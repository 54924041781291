import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import MaterialTable from 'material-table'
import * as actions from '../../store/actions'
import Spinner from '../UI/Spinner'
import moment from 'moment'

const Errors = ({ getSentryErrors, sentryErrors }) => {
	useEffect(() => {
		getSentryErrors()
	}, [getSentryErrors])

	const handleRowClick = (_, object) => {
		window.open(object.permalink)
	}

	const columns = [
		{
			title: 'Error',
			field: 'title',
			cellStyle: {
				whiteSpace: 'nowrap',
				maxWidth: '50em',
			},
		},
		{
			title: 'First Seen',
			field: 'firstSeen',
			render: (rowData) =>
				moment(rowData.firstSeen).format('MMMM Do YYYY, HH:mm:ss'),
		},
		{
			title: 'Last Seen',
			field: 'lastSeen',
			render: (rowData) =>
				moment(rowData.lastSeen).format('MMMM Do YYYY, HH:mm:ss'),
		},
		{ title: 'User Count', field: 'userCount', align: 'center' },
	]

	return (
		<div className='row'>
			<div className='col s12 errors-table'>
				<div style={{ maxWidth: '100%' }}>
					{sentryErrors.length !== 0 ? (
						<MaterialTable
							title={'Errors'}
							columns={columns}
							data={sentryErrors}
							options={{
								sorting: true,
								headerStyle: {
									fontFamily: 'system-ui',
									fontWeight: '600',
								},
								cellStyle: {
									fontSize: '13px',
								},
								emptyRowsWhenPaging: true,
								exportAllData: true,
								exportButton: true,
								pageSize: 10,
								pageSizeOptions: [10, 10 * 2, 10 * 5],
								actionsColumnIndex: -1,
							}}
							style={{ padding: '10px' }}
							onRowClick={(e, object) => handleRowClick(e, object)}
						/>
					) : (
						<Spinner />
					)}
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = ({ sentryErrors }) => {
	return { sentryErrors }
}

export default connect(mapStateToProps, actions)(Errors)
