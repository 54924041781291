import React from 'react'
import { connect } from 'react-redux'
import List from '../UI/List'
import { LineChart } from '../UI/Chart'
import moment from 'moment'

const Overview = ({ sandboxes, users }) => {
	let data = []

		; (() => {
			// finds latest 7 days
			for (let i = 0; i < 8; i++) {
				let lastSevenDays = moment()
					.subtract(7 - i, 'd')
					.format('YYYY-MM-DD')

				data.push({
					date: lastSevenDays,
					value: 0,
				})
			}
			const today = data[7].date

			if (sandboxes.length !== 0) {
				for (let i in sandboxes) {
					let dateCreated = moment(sandboxes[i].createdAt).format(
						'YYYY-MM-DD'
					)
					let difference = moment(today).diff(dateCreated, 'days')

					if (difference >= 0 && difference <= 7) {
						for (let n in data) {
							if (data[n].date === dateCreated) {
								data[n].value++
							}
						}
					}
				}
			}
		})()

	let dataset = []
		; (() => {
			if (sandboxes.length !== 0) {
				let temp = []
				for (let i in sandboxes) {
					temp.push({
						date: moment(sandboxes[i].createdAt).format('YYYY-MM-DD')
					})
				}
				temp.sort((a, b) => a.date > b.date ? 1 : -1)
				let total = 0;
				const func = (arr, i) => {arr.filter((a) => a.label === temp[i].date).map((a) => a.value = ++total)} // moved from loop to avoid linting error
				for (let i in temp) {
					if (dataset.filter((a) => a.label === temp[i].date).length < 1) {
						dataset.push({
							label: temp[i].date,
							value: ++total
						})
					} else {
						func(dataset, i)
					}
				}
			}
		})()

	return (
		<div className='overview-container'>
			<LineChart
				isDetailPage={false}
				data={[
					{
						text: 'Total',
						count: sandboxes.length,
						values: {
							labels: dataset.map((a) => moment(a.label).format("DD/MM/YY")),
							datasets: [
								{
									label: 'Total sandboxes',
									fill: false,
									lineTension: 0.3,
									backgroundColor: '#2C3A51',
									borderColor: '#2C3A51',
									pointRadius: 3,
									data: dataset.map((a) => a.value)
								},
							],
						},
					},
					{
						text: 'New (7 days)',
						count: data.reduce((a, b) => +a + +b.value, 0),
						values: {
							labels: data.map((a) => moment(a.date).format("MMM Do")),
							datasets: [
								{
									label: 'Sandboxes',
									fill: false,
									lineTension: 0.1,
									backgroundColor: '#2C3A51',
									borderColor: '#2C3A51',
									pointRadius: 3,
									data: data.map((a) => a.value),
								},
							],
						},
					},
				]}
			/>
			<List
				pageSize={5}
				columns={[
					{ title: 'ID', field: 'sandboxId' },
					{ title: 'Namespace', field: 'namespace' },
					{ title: 'Customer', field: 'owner.userName' },
					{
						title: 'Provisioned',
						field: 'provisioned',
						lookup: {
							false: (
								<span
									className='material-icons red-text'
									style={{ marginRight: '20px' }}
								>
									fiber_manual_record
								</span>
							),
							true: (
								<span
									className='material-icons green-text'
									style={{ marginRight: '20px' }}
								>
									fiber_manual_record
								</span>
							),
						},
						customSort: (a, b) => a.active - b.active,
						align: 'center',
					},
				]}
				data={sandboxes}
				users={users}
				actions={'delete'}
			/>
		</div>
	)
}

const mapStateToProps = ({ sandboxes, users }) => {
	return { sandboxes, users }
}

export default connect(mapStateToProps)(Overview)
