import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
// import { errorNotyf } from '../UI/Notification';
import Spinner from '../UI/Spinner';
import TopMenu from '../UI/TopMenu';
import moment from 'moment';
import {LineChart} from '../UI/Chart';
import NotFound from '../UI/NotFound';
import M from 'materialize-css/dist/js/materialize.min.js';
import './Sandboxes.css';

class DetailedSandbox extends Component {
  state = { isLoading: true, 
            sandboxExists: true,
            validating: false,
            updatingProvision: false,
            creatingBankEngine: false,
            deletingBankEngine: false,
            creatingDatabase: false,
            deletingDatabase: false,
            creatingNamespace: false,
            deletingNamespace: false,
            deletingProvision: false,
            selectedBankserviceImage: "",
            selectedMigratorImage: ""
          };

  componentDidMount = () => {
    this.getCurrentSandbox();
    this.getAvailableTags();
  };

  // componentDidUpdate = () => {
  //   if (this.props.currentSandbox.error) {
  //     errorNotyf(this.props.currentSandbox.error);
  //     delete this.props.currentSandbox.error;
  //   }
  // };

  getCurrentSandbox = () => {
    const ID = this.props.match.params.id;

    this.props
      .getSandbox(ID)
      .then((_) => {this.customizeChart(); this.setCurrentState()})
      .catch((_) => this.setState({ sandboxExists: false, isLoading: false }));
  };
  
  getAvailableTags = () => {
    this.props.getImageTags()
  }

  createToast = (message) => {
    var toastHTML = `<span>${message}</span><button class="btn-flat toast-action" style="color: white" onClick="M.Toast.dismissAll()">&#10005;</button>`
    M.toast({html: toastHTML, displayLength: 4000, classes: "chip-danger"});
  };

  verifyCurrentSandbox = (sandboxId) => {
    this.setState({ validating: true });
    this.props
      .sandboxVerify(sandboxId)
      .then((_) =>  this.setState({ validating: false }))
      .catch((_) => {this.createToast("Validation failed"); this.setState({ validating: false })});
  };

  handleUpdateProvisionClick = (sandboxId) => {
    this.setState({ updatingProvision: true});
    this.props
     .updateProvision(sandboxId)
     .then((_) => this.setState({ updatingProvision: false}))
     .catch((_) => {this.createToast("Unable to provision sandbox"); this.setState({ updatingProvision: false})});
  };

  customizeChart = () => {
    let { latestActivity } = this.props.currentSandbox.sandboxStatus;

    // finds latest 7 days
    let data = [];
    for (let i = 0; i < 8; i++) {
      let lastSevenDays = moment()
        .subtract(7 - i, 'd')
        .format('YYYY-MM-DD');

      data.push({
        date: lastSevenDays,
        value: 0,
      });
    }
    const today = data[7].date;
    for (let i in latestActivity) {
      let lastActivity = moment(latestActivity[i]).format('YYYY-MM-DD');
      let difference = moment(today).diff(lastActivity, 'days');

      if (difference >= 0 && difference <= 7) {
        for (let n in data) {
          if (data[n].date === lastActivity) {
            data[n].value++;
          }
        }
      }
    }

    this.labels = data.map((a) => moment(a.date).format('DD/MM'));
    this.chart_data = data.map((a) => a.value);

    this.setState({ isLoading: false });
  };

  
	handleChartClick = () => {
    let ID = this.props.match.params.id
    let path = `/sandboxes/${ID}/detailed-activity`
		this.props.history.push(path)
	};

  handleDeleteClick = () => {
    const elem = document.getElementById('deleteModal');
    M.Modal.init(elem).open();
  };

  deleteSandboxHandler = () => {
    this.props.deleteSandbox(this.props.currentSandbox.sandboxId);
    this.props.history.push('/sandboxes');
  };



  provisionButton = (sandboxId) => {
    return (
      <button
        type='button'
        onClick={() => {
          this.setState({updatingProvision: true});
          this.props
          .sandboxProvision(sandboxId)
          .then((_) => this.setState({ updatingProvision: false}))
          .catch((_) => {this.createToast("Unable to provision sandbox"); this.setState({ updatingProvision: false})});
        }}
        className={`chip ${
          this.state.updatingProvision
            ? 'chip-update chip-update'
            : 'chip-provision chip-provision'}`}
      >
        <span className='material-icons delete-icon'>cloud</span>
        <span>{
          this.state.updatingProvision
          ? 'Provisioning'
          : 'Provision'
        }
        </span>
      </button>
    );
  };
  
  reProvisionButton = (sandboxId) => {
    return (
      <button
        type='button'
        onClick={() => {
          this.setState({updatingProvision: true});
          this.props
          .sandboxReprovision(sandboxId)
          .then((_) => this.setState({ updatingProvision: false}))
          .catch((_) => {this.createToast("Unable to reprovision sandbox"); this.setState({ updatingProvision: false})});
        }}
        className={`chip ${
          this.state.updatingProvision
            ? 'chip-update chip-update'
            : 'chip-provision chip-provision'}`}
      >
        <span className='material-icons delete-icon'>cloud</span>
        <span>{
          this.state.updatingProvision
          ? 'Provisioning'
          : 'Reprovision'
        }
        </span>
      </button>
    );
  };
  

  updateProvisionButton = (sandboxId) => {
    return (
      <button
        type='button'
        onClick={() => this.handleUpdateProvisionClick(sandboxId)}
        className={`chip ${
          this.state.updatingProvision
            ? 'chip-update chip-update'
            : 'chip-provision chip-provision'}`}
      >
        <span className='material-icons delete-icon'>update</span>
        <span>{this.state.updatingProvision
            ? 'Updating'
            : 'Update Provision'
            }
        </span>
      </button>
    )
  }

  provisionNamespaceButton = (sandboxId) => {
    return (
      <button
        type="button"
        onClick={() => {
          this.setState( {creatingNamespace: true});
          this.props
            .sandboxProvisionNamespace(sandboxId)
            .then((_) => this.setState( {creatingNamespace: false} ))
            .catch((_) => {this.createToast("Unable to create namespace"); this.setState({creatingNamespace: false})})
        }}
        className={`chip ${
          this.state.creatingNamespace
            ? 'chip-update chip-update'
            : 'chip-provision chip-provision'}`}
      >
        <span className='material-icons delete-icon'>cloud</span>
        <span>{this.state.creatingNamespace
            ? 'Creating namespace'
            :'Create namespace'}
        </span>
      </button>
    )
  }

  provisionDatabaseButton = (sandboxId) => {
    return (
      <button
        type="button"
        onClick={() => {
          this.setState( {creatingDatabase: true});
          this.props
            .sandboxProvisionDatabase(sandboxId)
            .then((_) => this.setState( {creatingDatabase: false} ))
            .catch((_) => {this.createToast("Unable to create database"); this.setState({creatingDatabase: false})})
        }}
        className={`chip ${
          this.state.creatingDatabase
            ? 'chip-update chip-update'
            : 'chip-provision chip-provision'}`}
      >
        <span className='material-icons delete-icon'>cloud</span>
        <span>{this.state.creatingDatabase
            ? 'Creating database'
            :'Create database'}
        </span>
      </button>
    )
  }

  deleteButton = (sandboxId) => {
    return (
      <button
        type='button'
        onClick={() => {
          this.setState( {deletingProvision: true});
          this.props
          .sandboxDelete(sandboxId)
          .then((_) => this.setState( {deletingProvision: false}))
          .catch((_) => {this.createToast("Unable to delete provision"); this.setState({deletingProvision: false})})
        }}
        className={`chip ${
          this.state.deletingProvision
            ? 'chip-danger chip-delete'
            : 'chip-provision chip-provision'}`}
      >
        <span className='material-icons delete-icon'>cloud_off</span>
        <span>{this.state.deletingDatabase
            ? 'Deleting Provision'
            :'Delete Provision'}
        </span>
      </button>
    );
  };

  handleDeleteDatabaseClick = (sandboxId) => {
    this.setState({ deletingDatabase: true});
    this.props
      .sandboxDeleteDatabase(sandboxId)
      .then((_) => this.setState( { deletingDatabase: false}))
      .catch((_) => {this.createToast("Unable to delete database"); this.setState( { deletingDatabase: false})})
  }

  deleteDatabaseButton = (sandboxId) => {
    return (
      <button
        type='button'
        onClick={() => this.handleDeleteDatabaseClick(sandboxId)}
        className={`chip ${
          this.state.deletingDatabase
            ? 'chip-danger chip-delete'
            : 'chip-provision chip-provision'}`}
      >
        <span className='material-icons delete-icon'>cloud_off</span>
        <span>
          {this.state.deletingDatabase
            ? 'Deleting Database'
            :'Delete Database'}
        </span>
      </button>
    );
  };

  handleDeleteNamespaceClick = (sandboxId) => {
    this.setState( {deletingNamespace: true})
    this.props
      .sandboxDeleteNamespace(sandboxId)
      .then((_) => this.setState({ deletingNamespace: false}))
      .catch((_) => {this.createToast("Unable to delete namespace"); this.setState({ deletingNamespace: false})});
  };

  deleteNamespaceButton = (sandboxId) => {
    return (
      <button
        type='button'
        onClick={() => this.handleDeleteNamespaceClick(sandboxId)}
        className={`chip ${
          this.state.deletingNamespace
            ? 'chip-danger chip-delete'
            :'chip-provision chip-provision'}`}
      >
        <span className='material-icons delete-icon'>cloud_off</span>
        <span>
          {this.state.deletingNamespace
            ? 'Deleting Namespace'
            : 'Delete Namespace'
          }        
        </span>
      </button>
    );
  };

  handleCreateBankEngineClick = (sandboxId) => {
    this.setState({ creatingBankEngine: true});
    this.props
      .sandboxCreateBankEngine(sandboxId)
      .then((_) => this.setState({ creatingBankEngine: false}))
      .catch((_) => {this.createToast("Unable to create bank engine"); this.setState({ creatingBankEngine: false})});
  };

  createBankEngineButton = (sandboxId) => {
    return (
      <button
        type='button'
        onClick={() => this.handleCreateBankEngineClick(sandboxId)}
        className={`chip ${
          this.state.creatingBankEngine
            ? 'chip-update chip-update'
            : 'chip-provision chip-provision'
          }`}
      >
        <span className='material-icons delete-icon'>cloud</span>
        <span>
          {this.state.creatingBankEngine
            ? 'Creating Bank Engine'
            : 'Create Bank Engine'
          }
        </span>
      </button>
    )
  };

  handleDeleteBankEngineClick = (sandboxId) => {
    this.setState({ deletingBankEngine: true});
    this.props
      .sandboxDeleteBankEngine(sandboxId)
      .then((_) => this.setState({ deletingBankEngine: false}))
      .catch((_) => {this.createToast("Unable to delete bank engine"); this.setState({ deletingBankEngine: false})});
  };

  deleteBankEngineButton = (sandboxId) => {
    return (
      <button
        type='button'
        onClick={()=> this.handleDeleteBankEngineClick(sandboxId)}
        className={`chip ${
          this.state.deletingBankEngine
            ? 'chip-danger chip-delete'
            : 'chip-provision chip-provision'}`}
      >
        <span className='material-icons delete-icon'>cloud_off</span>
        <span>
          {this.state.deletingBankEngine
            ? 'Deleting Bank Engine'
            : 'Delete Bank Engine'}</span>
      </button>
    )
  };

  renderVerifyButton = ({ sandboxId, provisioned }) => {
    return (
      <button
        type='button'
        onClick={() => this.verifyCurrentSandbox(sandboxId)}
        className={`chip ${
          this.state.validating
            ? 'chip-provision chip-provision'
            : provisioned
            ? 'chip-verified chip-verified'
            : 'chip-danger chip-delete'
        }`}
      >
        <span className='material-icons delete-icon'>
          {this.state.validating ? 'cloud' : 'cloud_done'}
        </span>
        <span>
          {this.state.validating
            ? 'Verifying'
            : provisioned
            ? 'Verified'
            : 'Verify'}
        </span>
      </button>
    );
  };

  handleUpdateClick = () =>{
		const elem = document.getElementById('updateAPIModal')
		const serviceSelectElems = document.querySelectorAll('.bankserviceTags')
		const migratorSelectElems = document.querySelectorAll('.migratorTags')
		M.Modal.init(elem).open()
		M.FormSelect.init(serviceSelectElems)
		M.FormSelect.init(migratorSelectElems)
  }
  handleBankserviceChange = (e) =>{
    this.setState({selectedBankserviceImage: e.target.value})
  }
  handleMigratorChange = (e) =>{
    this.setState({selectedMigratorImage: e.target.value})
  }

  updateAPIHandler = (serviceTag, migratorTag, sandboxId) =>{
    if(serviceTag !== "" && migratorTag !== ""){
      this.props.updateAPIPod(serviceTag, migratorTag, sandboxId) 
    }
  }
  updateAPIButton = () => {
    return(
      <button
        type='button'
        onClick={() => this.handleUpdateClick()}
        className='chip chip-provision chip-provision'
      >
        <span className='material-icons delete-icon'>cloud_upload</span>
        <span>
          Change Image
        </span>
      </button>
    );
  };

  renderProvisioningButtons = ({ sandboxId, provisioningStatus }) => {
    switch (provisioningStatus) {
      case 0:
        return this.provisionButton(sandboxId);
      case 1:        
        return this.provisionButton(sandboxId);
      case 2:
        return this.provisionButton(sandboxId);
      case 3:
        return <></>;
      case 4:
        return (
          <>
            <>{this.deleteButton(sandboxId)}</>
          </>
        )
      case 5:
        return (
          <>
            <>{this.deleteDatabaseButton(sandboxId)}</>
            <>{this.deleteNamespaceButton(sandboxId)}</>
            <>{this.deleteButton(sandboxId)}</>
            <>{this.props.currentSandbox.sandboxStatus.hasBankEngine ? this.deleteBankEngineButton(sandboxId) : this.createBankEngineButton(sandboxId)}</>
            <>{this.updateAPIButton()}</>
            <>{this.updateProvisionButton(sandboxId)}</>            
          </>
        );
      case 6:
        return (
          <>
            <>{this.deleteDatabaseButton(sandboxId)}</>
            <>{this.deleteNamespaceButton(sandboxId)}</>
            <>{this.deleteButton(sandboxId)}</>
          </>
        );
      case 7:
        return (
          <>
            <>{this.deleteDatabaseButton(sandboxId)}</>
            <>{this.deleteNamespaceButton(sandboxId)}</>
            <>{this.deleteButton(sandboxId)}</>                    
          </>
        );
      case 8:
        return this.provisionDatabaseButton(sandboxId);
      case 9:
        return this.provisionNamespaceButton(sandboxId);
      case 10:
        return this.provisionButton(sandboxId);
      case 11:
        return this.reProvisionButton(sandboxId);
      default:
        return null;
    }
  };

  renderProvisioningStatus = ({ provisioningStatus }) => {
    switch (provisioningStatus) {
      case 0:
        return 'Initialized';
      case 1:
        return 'Creating Database';
      case 2:
        return 'Creating Namespace';
      case 3:
        return 'Verifying'
      case 4:
        return 'Unable to verify'
      case 5:
        return 'Created';
      case 6:
        return 'Deleting Database';
      case 7:
        return 'Deleting Namespace';
      case 8:
        return 'Database Deleted'
      case 9:
        return 'Namespace Deleted'
      case 10:
        return 'Deleted';
      case 11:
        return 'Inactive (Older than 30 days)'
      default:
        return null;
    }
  };

  setCurrentState = () => {
    let { provisioningStatus }= this.props.currentSandbox
    switch (provisioningStatus){
      case 1:
        this.setState({updatingProvision: true})
        break;
      case 2:
        this.setState({updatingProvision: true})
        break;
      case 6:
        this.setState({deletingDatabase: true})
        break;
      case 7:
        this.setState({deletingNamespace: true})
        break;
      default:
        return null;
    }
  }
  labels = [];
  chart_data = [];


  render() {
    let { sandboxExists, isLoading } = this.state;
    if (!sandboxExists) return <NotFound />;
    if (isLoading) return <Spinner />;

    let { currentSandbox } = this.props;
    let {availableTags} = this.props;
    let latestActivity;
    if (currentSandbox !== null) {
      if (currentSandbox.sandboxStatus.latestActivity === undefined || currentSandbox.sandboxStatus.latestActivity.length < 1)
        latestActivity = 'Error getting last activity...';
      else {
        let arr = currentSandbox.sandboxStatus.latestActivity;
        latestActivity = moment(arr[arr.length - 2]).format(
          'MMMM Do YYYY, HH:mm:ss'
        );
      }
    }

    let createdAt;
    if (currentSandbox !== null) {
        createdAt = moment(currentSandbox.createdAt).format(
          'MMMM Do YYYY, HH:mm:ss'
        );
    }

    return (currentSandbox !== null && availableTags.bankservice !== undefined) ? (
      <>
        <div className='container'>
          <div className='row' style={{ marginBottom: '50px' }}>
            <TopMenu
              title='Sandboxes'
              currentDetailPage={`Sandbox #${currentSandbox.sandboxId}`}
            />
            <div className='col s12'>
              <h4 className='menu-header'>{currentSandbox.name}</h4>
              {this.renderProvisioningButtons(currentSandbox)}
              {this.renderVerifyButton(currentSandbox)}
              <button
                type='button'
                onClick={this.handleDeleteClick}
                className='chip chip-danger chip-delete'
              >
                <span className='material-icons delete-icon'>delete</span>
                <span>Delete</span>
              </button>
              <h6 className='menu-subheader'>
                Status: {this.renderProvisioningStatus(currentSandbox)}
              </h6>
            </div>
          </div>
          <div className='row'>
            <div className='col s6'>
              <div className='card details-card'>
                <div className='card-info'>
                  <div className='col s12'>
                    <h6>Sandbox #{currentSandbox.sandboxId}</h6>
                    <br />
                    <div className='row'>
                      <div className='input-field col s12'>
                        <i className='material-icons prefix'>dns</i>
                        <input
                          disabled
                          value={currentSandbox.randomNS}
                          type='text'
                          className='validate'
                        />
                        <label className='active'>Namespace</label>
                      </div>
                      <div className='input-field col s12'>
                        <i className='material-icons prefix'>link</i>
                        <input
                          disabled
                          value={currentSandbox.sandboxURL}
                          type='text'
                          className='validate'
                        />
                        <label className='active'>URL</label>
                      </div>
                      <div className='input-field col s12'>
                        <i className='material-icons prefix'>schedule</i>
                        <input
                          disabled
                          value={latestActivity}
                          type='text'
                          className='validate'
                        />
                        <label className='active'>Last Activity</label>
                      </div>
                      <div className='input-field col s12'>
                        <i className='material-icons prefix'>event</i>
                        <input
                          disabled
                          value={createdAt}
                          type='text'
                          className='validate'
                        />
                        <label className='active'>Created At</label>
                      </div>
                      <div className='input-field col s12'>
                        <i className='material-icons prefix'>collections</i>
                        <input
                          disabled
                          value={currentSandbox.sandboxStatus.imageTag}
                          type='text'
                          className='validate'
                        />
                        <label className='active'>API Image</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col s6'>
              <div className='card details-card'>
                <div className='card-info'>
                  <div className='col s12'>
                    <h6>Customer</h6>
                    <br />
                    <div className='row'>
                      <div className='input-field col s12'>
                        <i className='material-icons prefix'>
                          <Link
                            className='blue-text'
                            to={`/customers/${currentSandbox.owner.userId}`}
                          >
                            perm_identity
                          </Link>
                        </i>
                        <input
                          disabled
                          value={currentSandbox.owner.userId}
                          type='text'
                          className='validate'
                        />
                        <label className='active'>ID</label>
                      </div>
                      <div className='input-field col s12'>
                        <i className='material-icons prefix'>email</i>
                        <input
                          disabled
                          value={currentSandbox.owner.userName}
                          type='text'
                          className='validate'
                        />
                        <label className='active'>Email</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col s6'>
              <div className='card details-card' style={{ height: '330px' }}>
                <div className='card-info'>
                  <div className='col s12'>
                    <h6>State</h6>
                    <br />
                    <div className='row'>
                      <div className='col s8'>
                        <div className='chip chip-info'>Database has data</div>
                      </div>
                      <div className='col s4'>
                        {currentSandbox.sandboxStatus.dbHasData === false ? (
                          <span className='material-icons red-text'>
                            fiber_manual_record
                          </span>
                        ) : (
                          <span className='material-icons green-text'>
                            fiber_manual_record
                          </span>
                        )}
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col s8'>
                        <div className='chip chip-info'>Certificate</div>
                      </div>
                      <div className='col s4'>
                        {currentSandbox.sandboxStatus.hasCertificate ===
                        false ? (
                          <span className='material-icons red-text'>
                            fiber_manual_record
                          </span>
                        ) : (
                          <span className='material-icons green-text'>
                            fiber_manual_record
                          </span>
                        )}
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col s8'>
                        <div className='chip chip-info'>Alive</div>
                      </div>
                      <div className='col s4'>
                        {currentSandbox.sandboxStatus.podAlive === false ? (
                          <span className='material-icons red-text'>
                            fiber_manual_record
                          </span>
                        ) : (
                          <span className='material-icons green-text'>
                            fiber_manual_record
                          </span>
                        )}
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col s8'>
                        <div className='chip chip-info'>Bank Engine</div>                        
                      </div>
                      <div className='col s4'>
                        {currentSandbox.sandboxStatus.hasBankEngine === false ? (
                          <span className='material-icons red-text'>
                            fiber_manual_record
                          </span>
                        ) : (
                          <span className='material-icons green-text'>
                            fiber_manual_record
                          </span>
                        )}
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col s6'>Status Updated:</div>
                      <div className='col s6'>
                        {moment(currentSandbox.statusUpdate).format(
                          'MMMM Do YYYY, HH:mm:ss'
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col s6 col-chart-detail chart-click' onClick={this.handleChartClick}>
              <LineChart
                data={[
                  {
                    text: 'Activity last 7 days',
                    values: {
                      labels: this.labels,
                      datasets: [
                        {
                          label: 'Daily requests',
                          fill: false,
                          lineTension: 0.1,
                          backgroundColor: '#2C3A51',
                          borderColor: '#2C3A51',
                          pointRadius: 5.0,
                          data: this.chart_data,
                        },
                      ],
                    },
                  },
                ]}
                isDetailPage={true}
              />
            </div>
          </div>
        </div>

        <div id='deleteModal' className='modal'>
          <div className='modal-content'>
            <h6>Delete Sandbox #{currentSandbox.sandboxId}</h6>
            <p style={{ marginTop: '30px' }}>
              Are you sure you want to delete the current sandbox?
            </p>
            <div className='row'>
              <div className='input-field col s6'>
                <input
                  disabled
                  value={currentSandbox.name}
                  type='text'
                  className='validate'
                />
                <label className='active'>Name</label>
              </div>
              <div className='input-field col s6' style={{ marginTop: '15px' }}>
                <input
                  disabled
                  value={currentSandbox.owner.userName}
                  type='text'
                  className='validate'
                />
                <label className='active'>Owner</label>
              </div>
            </div>
          </div>
          <div className='modal-footer'>
            <Link
              to='#'
              onClick={this.deleteSandboxHandler}
              className='modal-close btn-small red'
              style={{ marginRight: '10px' }}
            >
              Delete
            </Link>
            <Link to='#' className='modal-close btn-flat'>
              Close
            </Link>
          </div>
        </div>
        <div id='updateAPIModal' className='modal col s6'>
				<form onSubmit={this.updateAPIHandler(this.state.selectedBankserviceImage, this.state.selectedMigratorImage, currentSandbox.sandboxId)}>
				<div className='modal-content'>
					<div className='row'>
						<h6>Update API image for Sandbox #{currentSandbox.sandboxId}</h6>
						<div className="input-field col s6">							
            <select className="bankserviceTags" defaultValue="" onChange={this.handleBankserviceChange}>
								<option value="" disabled>Choose Bankservice Image</option>																					
								{this.props.availableTags.bankservice.map(tag => (																		
								<option key={tag} value={tag}>
								 	{tag}
								</option>																	
								))}
							</select>
							<label>Available Bankservice Images</label>
						</div>						
					</div>
					<div className='row'>
						<div className="input-field col s6">
							<select className="migratorTags" defaultValue="" onChange={this.handleMigratorChange}>
								<option value="" disabled>Choose Migrator Image</option>
								{this.props.availableTags.migrator.map(tag => (
									<option key={tag} value={tag}>
										{tag}
									</option>
								))}
							</select>
							<label>Available Migrator Images</label>
						</div>
					</div>
					<p>
						Are you sure you want to restart the sandbox pod and apply the selected image?
					</p>
				</div>
				<div className='modal-footer'>
					<button type='submit' className='btn-small modal-create-btn'>
						Restart Pod
					</button>
					<Link to='#' className='modal-close btn-flat'>
						Close
					</Link>
				</div>
				</form>
			</div>
      </>
    ) : (
      <Spinner />
    );
  }
}

const mapStateToProps = ({ currentSandbox, availableTags }) => {
  return { currentSandbox, availableTags };
};

export default connect(mapStateToProps, actions)(DetailedSandbox);
