import 'materialize-css/dist/css/materialize.min.css'
import React from 'react'
import ReactDOM from 'react-dom'
import { processSilentRenew } from 'redux-oidc'
import App from './components/App'
import Root from './components/Root'

if (window.location.pathname === '/oidc-silent-renew') {
	processSilentRenew()
} else {
	ReactDOM.render(
		<Root>
			<App />
		</Root>,
		document.querySelector('#root')
	)
}
