import { createUserManager } from 'redux-oidc'

const userManagerConfig = {
	client_id: 'SandboxAdmin',
	redirect_uri: `${window.location.protocol}//${window.location.hostname}${
		window.location.port ? `:${window.location.port}` : ''
	}/oidc-callback`,
	response_type: 'id_token token',
	scope: 'openid profile sandbox',
	authority: window._env_.IDENTITY_SERVER_URL,
	silent_redirect_uri: `${window.location.protocol}//${
		window.location.hostname
	}${window.location.port ? `:${window.location.port}` : ''}/oidc-silent-renew`,
	automaticSilentRenew: true,
	filterProtocolClaims: true,
	loadUserInfo: true,
}

const userManager = createUserManager(userManagerConfig)

export default userManager
