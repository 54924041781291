import { CHANGE_CURRENT_TAB } from '../actions/types'

export default (state = 'overview', action) => {
	switch (action.type) {
		case CHANGE_CURRENT_TAB:
			return action.payload
		default:
			return state
	}
}
