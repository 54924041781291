export const GET_ALL_SANDBOXES = 'GET_ALL_SANDBOXES'
export const GET_SANDBOX = 'GET_SANDBOX'
export const GET_SANDBOX_OF_USER = 'GET_SANDBOX_OF_USER'
export const GET_SANDBOX_FAILED = 'GET_SANDBOX_FAILED'
export const POST_SANDBOX = 'POST_SANDBOX'
export const DELETE_SANDBOX = 'DELETE_SANDBOX'
export const CHANGE_SANDBOX_STATUS = 'CHANGE_SANDBOX_STATUS'
export const SANDBOX_NOT_VERIFIED = 'SANDBOX_NOT_VERIFIED'
export const GET_SANDBOX_PODSTATUS = 'GET_SANDBOX_PODSTATUS'
export const GET_SANDBOX_CERTIFICATE = 'GET_SANDBOX_CERTIFICATE'
export const GET_SANDBOX_DBSTATUS = 'GET_SANDBOX_DBSTATUS'
export const GET_SANDBOX_LASTACTIVITY = 'GET_SANDBOX_LASTACTIVITY'
export const GET_ALL_USERS = 'GET_ALL_USERS'
export const GET_USER = 'GET_USER'
export const UPDATED_USER = 'UPDATED_USER'
export const EMAIL_SENT = 'EMAIL_SENT'
export const EMAIL_SENT_ERROR = 'EMAIL_SENT_ERROR'
export const PREVIEW_EMAIL = 'SENT_EMAIL'
export const PREVIEW_EMAIL_ERROR = 'SENT_EMAIL_ERROR'
export const UPDATED_USER_ERROR = 'UPDATED_USER_ERROR'
export const CHANGE_CURRENT_TAB = 'CHANGE_CURRENT_TAB'
export const GET_SENTRY_ERRORS = 'GET_SENTRY_ERRORS'
export const API_ERROR = 'API_ERROR'
export const GET_SANDBOX_ACTIVITY = 'GET_SANDBOX_ACTIVITY'
export const UPDATE_API = 'UPDATE_API'
export const UPDATE_API_POD = 'UPDATE_API_POD'
export const GET_IMAGE_TAGS = 'GET_IMAGE_TAGS'
export const CHANGE_BANK_ENGINE_STATUS = 'CHANGE_BANK_ENGINE_STATUS'
export const UPDATE_PROVISION = 'UPDATE_PROVISION'