import React from 'react'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import history from '../config/history'
import { OidcProvider, loadUser } from 'redux-oidc'
import userManager from '../tools/userManager'
import store from '../store'

export default ({ children }) => {
	loadUser(store, userManager)

	return (
		<Provider store={store}>
			<Router history={history}>
				<OidcProvider userManager={userManager} store={store}>
					{children}
				</OidcProvider>
			</Router>
		</Provider>
	)
}
