import React from 'react'
import { connect } from 'react-redux'
import List from '../UI/List'
import {LineChart} from '../UI/Chart'
import moment from 'moment'

const Overview = ({ users }) => {
  let activeCustomers = users.filter(
    (u) => u.emailConfirmed && u.phoneNumberConfirmed
  ).length

  let data = []
  
  ;(()=>{
		if(users.length !==0){
			let temp = []
			for (let i in users){
				temp.push({
					date: moment(users[i].createdDate).format('YYYY-MM-DD')
				})
			}
			temp.sort((a, b) => a.date > b.date ? 1: -1)
      let total = 0;
      const func = (arr, i) => { arr.filter((a) => a.label === temp[i].date).map((a) => a.value = ++total )} // moved from loop to avoid linting error
			for (let i in temp){
				if(data.filter((a)=> a.label === temp[i].date).length < 1){
					data.push({
						label: temp[i].date,
						value: ++total
					})
				}else{
					
					func(data, i)
				}
				
			}
		}
	})()

  return (
    <div className='overview-container'>
      <div className="row">
      <LineChart
        data={[
          {
            text: 'Total',
            count: users.length,
            isDetailPage: false,
            values: {
              labels: data.map((a) => moment(a.label).format("DD/MM/YY")),
              datasets: [
                {
                  label: 'Total Users',
                  fill: false,
                  lineTension: 0.4,
                  backgroundColor: '#2C3A51',
                  borderColor: '#2C3A51',
                  pointRadius: 3,
                  data: data.map((a) => a.value)
                },
              ],
            },
          },
          {
            text: 'Active',
            count: activeCustomers,
            isPie: true,
            isDetailPage: false,
            values: {
              labels: ['Active', 'Inactive'],
              datasets: [
                {
                  data: [activeCustomers, users.length-activeCustomers]
                
                },
              ],
            },
          }
        ]} />
      </div>
      <List
        pageSize={5}
        columns={[
          { title: 'Email', field: 'email' },
          { title: 'Company', field: 'company' },
          {
            title: 'Requested Production',
            field: 'requestedProduction',
            lookup: {
              false: (
                <span
                  className='material-icons red-text'
                  style={{ marginRight: '20px' }}
                >
                  fiber_manual_record
                </span>
              ),
              true: (
                <span
                  className='material-icons green-text'
                  style={{ marginRight: '20px' }}
                >
                  fiber_manual_record
                </span>
              ),
            },
            customSort: (a, b) => a.active - b.active,
            align: 'center',
          },
          {
            title: 'Email Confirmed',
            field: 'emailConfirmed',
            lookup: {
              false: (
                <span
                  className='material-icons red-text'
                  style={{ marginRight: '20px' }}
                >
                  fiber_manual_record
                </span>
              ),
              true: (
                <span
                  className='material-icons green-text'
                  style={{ marginRight: '20px' }}
                >
                  fiber_manual_record
                </span>
              ),
            },
            customSort: (a, b) => a.active - b.active,
            align: 'center',
          },
          {
            title: 'Phone Confirmed',
            field: 'phoneNumberConfirmed',
            lookup: {
              false: (
                <span
                  className='material-icons red-text'
                  style={{ marginRight: '20px' }}
                >
                  fiber_manual_record
                </span>
              ),
              true: (
                <span
                  className='material-icons green-text'
                  style={{ marginRight: '20px' }}
                >
                  fiber_manual_record
                </span>
              ),
            },
            customSort: (a, b) => a.active - b.active,
            align: 'center',
          },
        ]}
        data={users}
      />
    </div>
  )
}

const mapStateToProps = ({ users }) => {
  return { users }
}

export default connect(mapStateToProps)(Overview)
